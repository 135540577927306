import React, { useState, useEffect } from 'react';
import './ComingSoon.css'; // We'll add some CSS for styling

function ComingSoon({ launchDate }) {
    const calculateTimeLeft = () => {
        const difference = +new Date(launchDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    return (
        <div className="coming-soon">
            <div className="content">
                <h1>Coming Soon</h1>
                <p>We're working hard to bring you something amazing! Stay tuned.</p>
            </div>
        </div>
    );
}

export default ComingSoon;
