import React, { useState } from 'react';
import './FeedbackForm.css';

function FeedbackForm({ onNewFeedback, clientId }) {
    const [text, setText] = useState("");
    const [category, setCategory] = useState("Service");
    const [sentiment, setSentiment] = useState("AI Determined");
    const [rating, setRating] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showContactFields, setShowContactFields] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false); // Track form submission success
    const [errorMessage, setErrorMessage] = useState(null); // Track form submission error

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFeedback = { text, category, sentiment, rating, client_id: clientId, name, email, phone };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFeedback),
            });

            if (response.ok) {
                const data = await response.json();
                onNewFeedback(data);

                // Clear form fields after submission
                setText("");
                setCategory("Service");
                setSentiment("AI Determined");
                setRating(0);
                setName("");
                setEmail("");
                setPhone("");
                setShowContactFields(false);

                // Show success message
                setSubmitSuccess(true);
                setErrorMessage(null);

                // Hide success message after 3 seconds
                setTimeout(() => {
                    setSubmitSuccess(false);
                }, 3000);
            } else {
                setSubmitSuccess(false);
                setErrorMessage("Failed to submit feedback.");
            }
        } catch (error) {
            setSubmitSuccess(false);
            setErrorMessage("Error submitting feedback. Please try again.");
            console.error("Error submitting feedback:", error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="right-content">
            <h2>Feedback:</h2>
            {submitSuccess && <p className="success-message">Thank you for your feedback!</p>}  {/* Display success message */}
            {errorMessage && <p className="success-message">Thank you for your feedback!</p>}  {/* Display error message */}
            <input
                type='text'
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter your feedback"
                required
            />
            <select style={{width: '86%'}} value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="Service">Service</option>
                <option value="Product">Product</option>
                <option value="Support">Support</option>
            </select>

            <div className="toggle-contact-fields">
                <label className="form-options">
                    <input
                        type="checkbox"
                        checked={showContactFields}
                        onChange={() => setShowContactFields(!showContactFields)}
                    />
                    Provide Contact Information (Optional)
                </label>
            </div>

            {showContactFields && (
                <div className="contact-fields">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name (Optional)"
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email (Optional)"
                    />
                    <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Your Phone (Optional)"
                    />
                </div>
            )}

            <button type="submit">Submit Feedback</button>
        </form>
    );
}

export default FeedbackForm;
