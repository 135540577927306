import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import FeedbackCharts from './components/FeedbackCharts';
import './ClientFeedbackPage.css';

function ClientFeedbackPage() {
    const { clientId } = useParams();
    const [clientDetails, setClientDetails] = useState(null);
    const [feedbacks, setFeedbacks] = useState([]);
    const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [sentimentUpdating, setSentimentUpdating] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [keyword, setKeyword] = useState(""); // Add state for keyword search

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`);
                const data = await response.json();
                
                if (response.ok && data) {
                    setClientDetails(data.id ? data : (data.records.find(client => client.id === parseInt(clientId, 10)) || {}));
                } else {
                    setError("Failed to load client details.");
                }
            } catch (error) {
                console.error("Error fetching client details:", error);
                setError("An error occurred while fetching client details.");
            }
        };

        const fetchFeedbacks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?client_id=${clientId}`);
                const data = await response.json();
                if (response.ok) {
                    setFeedbacks(data.records || []);
                    setFilteredFeedbacks(data.records || []); // Initialize filtered feedbacks
                } else {
                    setError("Failed to load feedback.");
                }
            } catch (error) {
                console.error("Error fetching feedback:", error);
                setError("An error occurred while fetching feedback.");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
        fetchFeedbacks();
    }, [clientId]);

    const handleSentimentChange = async (feedbackId, newSentiment) => {
        setSentimentUpdating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?id=${feedbackId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: feedbackId,
                    sentiment: newSentiment,
                }),
            });
            if (response.ok) {
                const updatedFeedbacks = feedbacks.map(feedback => 
                    feedback.id === feedbackId ? { ...feedback, sentiment: newSentiment } : feedback
                );
                setFeedbacks(updatedFeedbacks);
                filterFeedbacks(updatedFeedbacks); // Reapply filter on sentiment change
            } else {
                alert('Failed to update sentiment.');
            }
        } catch (error) {
            console.error('Error updating sentiment:', error);
        } finally {
            setSentimentUpdating(false);
        }
    };

    const handleDeleteFeedback = async (feedbackId) => {
        if (window.confirm("Are you sure you want to delete this feedback?")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?id=${feedbackId}`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    const updatedFeedbacks = feedbacks.filter(feedback => feedback.id !== feedbackId);
                    setFeedbacks(updatedFeedbacks);
                    filterFeedbacks(updatedFeedbacks); // Reapply filter on deletion
                } else {
                    alert('Failed to delete feedback.');
                }
            } catch (error) {
                console.error('Error deleting feedback:', error);
            }
        }
    };

    const filterFeedbacks = (feedbackList = feedbacks) => {
        const filtered = feedbackList.filter(feedback => {
            const feedbackDate = new Date(feedback.date_submitted);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            const keywordMatch = feedback.text.toLowerCase().includes(keyword.toLowerCase()); // Keyword matching

            return (
                (!start || feedbackDate >= start) && 
                (!end || feedbackDate <= end) &&
                keywordMatch // Filter by keyword
            );
        });
        setFilteredFeedbacks(filtered);
    };

    const handleSearchChange = (e) => {
        setKeyword(e.target.value);
        filterFeedbacks(); // Filter feedbacks whenever the search keyword changes
    };

    const handleDateChange = () => {
        filterFeedbacks(); // Filter feedbacks based on date
    };

    return (
        <div className="client-feedback-container">
            <div className="overlay">
                <div className="feedback-container">
                    <Link to={`/client/${clientId}/submit-feedback`} target='_blank' className="back-link">Client Feedback Page</Link>
                    
                    {/* Replace header with logo */}
                    {clientDetails?.logo ? (
                        <div className="client-logo-container">
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${clientDetails.logo}`} alt={`${clientDetails.name} Logo`} className="client-logo" />
                        </div>
                    ) : (
                        <h2 className="client-feedback-header">Feedback for {clientDetails?.name || 'Unknown Client'}</h2>
                    )}

                    <div className="date-filter">
                        <label>Start Date: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /></label>
                        <label>End Date: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /></label>
                        <button onClick={handleDateChange}>Filter</button>
                    </div>

                    <div className="keyword-search">
                        <label>Search by Keyword: <input type="text" value={keyword} onChange={handleSearchChange} placeholder="Enter keyword" /></label>
                    </div>

                    {filteredFeedbacks.length > 0 && (
                        <div className="chart-container">
                            <FeedbackCharts feedbacks={filteredFeedbacks} />
                        </div>
                    )}
                </div>

                {loading ? (
                    <p>Loading feedback...</p>
                ) : error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <div className="feedback-content">
                        <ul className="feedback-list">
                            {filteredFeedbacks.length > 0 ? (
                                filteredFeedbacks.map(feedback => (
                                    <li key={feedback.id} className="feedback-item">
                                        <p><strong>Client:</strong> {clientDetails?.name || 'Unknown Client'}</p>
                                        <p><strong>Category:</strong> {feedback.category}</p>
                                        <p><strong>Feedback:</strong> {feedback.text}</p>
                                        <p><strong>Sentiment:</strong>
                                            <select 
                                                value={feedback.sentiment} 
                                                disabled={sentimentUpdating} 
                                                onChange={(e) => handleSentimentChange(feedback.id, e.target.value)}
                                            >
                                                <option value="Positive">Positive</option>
                                                <option value="Neutral">Neutral</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                        </p>
                                        <p><strong>Date Submitted:</strong> {feedback.date_submitted}</p>
                                        <button onClick={() => handleDeleteFeedback(feedback.id)} className="delete-button">Delete Feedback</button>
                                    </li>
                                ))
                            ) : (
                                <p>No feedback available for {clientDetails?.name || 'this client'}.</p>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ClientFeedbackPage;
