import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AdminDashboard.css'; // Import CSS for custom styling

function AdminDashboard() {
    const [clients, setClients] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php`);
            
            if (response.ok) {
                const data = await response.json();
                if (data && data.records) {
                    setClients(data.records);
                } else {
                    setError("No clients found.");
                }
            } else {
                setError(`Failed to load clients: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error fetching clients:", error);
            setError("An error occurred while fetching clients.");
        }
    };

    const handleDeleteClient = async (clientId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this client?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                await fetchClients(); // Refresh the client list after deletion
            } else {
                setError("Failed to delete client.");
            }
        } catch (error) {
            console.error("Error deleting client:", error);
            setError("An error occurred while deleting the client.");
        }
    };

    return (
        <div className="admin-dashboard-container">
            <h2>Admin Dashboard</h2>

            {/* Add link to Create User page */}
            <div className="create-user-link">
                <Link to="/create-user" target='_blank' className="create-user-button">
                    Create New User
                </Link>
            </div>

            {error && <p className="error-message">{error}</p>}

            <div className="existing-clients">
                <h3>Existing Clients</h3>
                <ul>
                    {clients.map(client => (
                        <li key={client.id} className="client-item">
                            <div className="client-info-home">
                                {client.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${client.logo}`} alt={`${client.name} logo`} className="client-logo" />}
                                <strong>{client.name}</strong>
                            </div>
                            <div className="client-actions">
                                <Link to={`/client/${client.id}/feedbacks`} className="client-link">
                                    View Feedback Summary
                                </Link>
                                <Link to={`/client/${client.id}/submit-feedback`} className="client-link">
                                    View Submit Feedback Page
                                </Link>
                                <button onClick={() => handleDeleteClient(client.id)} className="delete-client-button">
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default AdminDashboard;
