import React from 'react';
import './FeedbackList.css';

function FeedbackList({ feedbacks, handleNewFeedback }) {

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this feedback?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?id=${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                alert("Feedback deleted successfully.");
                // Filter out the deleted feedback from the state
                handleNewFeedback(feedbacks.filter(feedback => feedback.id !== id));
            } else {
                alert("Failed to delete feedback.");
            }
        } catch (error) {
            console.error("Error deleting feedback:", error);
        }
    };

    return (
        <div>
            <h2>Feedback List</h2>
            {feedbacks.length > 0 ? (
                <ul>
                    {feedbacks.map((feedback) => (
                        <li key={feedback.id}>
                            <p>{feedback.text}</p>
                            <p><strong>Category:</strong> {feedback.category}</p>
                            <p><strong>Sentiment:</strong> {feedback.sentiment}</p>
                            <p><strong>Date:</strong> {feedback.date_submitted}</p>
                            <button onClick={() => handleDelete(feedback.id)} className="delete-button">Delete</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No feedback found.</p>
            )}
        </div>
    );
}

export default FeedbackList;
