import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

function Login({ onLogin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, action: 'login' })
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    onLogin(data.token, data.role);  // Pass the token and role to the App component
                    navigate(data.dashboard_url);  // Redirect the user to their dashboard
                } else {
                    alert("Login failed! " + (data.message || ""));
                }
            } else {
                alert("Login failed! Server returned an error.");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("An error occurred while logging in. Please try again.");
        }
    };

    return (
        <div className="login">
            <form className="login-form" onSubmit={handleLogin}>
                <img src="./logo.png" alt="Logo" />
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <p style={{ color: 'white' }}>
                    Forgot Password? <Link to="/">reset here</Link>
                </p>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
