import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import CreateUser from './components/CreateUser';
import ClientFeedbackPage from './ClientFeedbackPage';
import ClientManagement from './components/ClientManagementPage';
import ClientFeedbackFormPage from './components/ClientFeedbackFormPage';
import ComingSoon from './ComingSoon';
import AdminDashboard from './components/AdminDashboard';
import Navbar from './components/Navbar'; // Import the Navbar

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState('');

    // Check authentication and role on page load
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const role = localStorage.getItem('userRole');  // Get the role from localStorage
        if (token) {
            setIsAuthenticated(true);
            setUserRole(role);
        }
    }, []);

    const handleLogin = (token, role) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userRole', role);  // Save the role in localStorage
        setIsAuthenticated(true);
        setUserRole(role);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userRole');
        setIsAuthenticated(false);
        setUserRole('');
    };

    return (
        <Router>
            <AppContent 
                isAuthenticated={isAuthenticated} 
                userRole={userRole} 
                onLogout={handleLogout} 
                onLogin={handleLogin} 
            />
        </Router>
    );
}

function AppContent({ isAuthenticated, onLogout, onLogin, userRole }) {
    const location = useLocation();  // Get the current location

    // Define the exact path where the navbar should be hidden
    const hideNavbar = location.pathname.includes('/submit-feedback', '/');

    return (
        <div>
            {/* Conditionally render the Navbar */}
            {isAuthenticated && !hideNavbar && <Navbar isAuthenticated={isAuthenticated} onLogout={onLogout} userRole={userRole} />}
            <Routes>
                <Route path="/" element={<Login onLogin={onLogin} />} />
                <Route path="/create-user" element={<CreateUser />} />
                <Route path="/client/:clientId" element={<ClientFeedbackPage />} />
                <Route path="/clients" element={<ClientManagement />} />
                <Route path="/client/:clientId/feedbacks" element={<ClientFeedbackPage />} />
                <Route path="/client/:clientId/submit-feedback" element={<ClientFeedbackFormPage />} />
                <Route path="/client-insights" element={<ComingSoon />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/dashboard" element={<Dashboard role="client" />} />
            </Routes>
        </div>
    );
}

export default App;
