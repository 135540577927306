import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Import your custom CSS for navbar styling

const Navbar = ({ isAuthenticated, onLogout }) => {
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');  // Get the role from localStorage

    const handleLogout = () => {
        onLogout();
        navigate('/');  // Redirect to login page after logout
    };

    return (
        isAuthenticated && (
            <nav className="navbar">
                <ul className="navbar-links">
                    {/* Conditionally render the Admin Dashboard link if the user is an admin */}
                    {userRole === 'admin' && (
                        <li><Link to="/admin/dashboard">Admin Dashboard</Link></li>
                    )}
                    {/* Add more links for authenticated users */}
                </ul>
                <div className="navbar-account">
                    <span>Account</span>
                    <button className="logout-button" onClick={handleLogout}>Logout</button>
                </div>
            </nav>
        )
    );
};

export default Navbar;
