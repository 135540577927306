import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeedbackList from './FeedbackList';
import './Dashboard.css';

function Dashboard({ role }) {
    const { clientId } = useParams();  // Extract clientId from URL (optional for admins)
    const [feedbacks, setFeedbacks] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            let endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/feedback.php`;
            if (role === 'client') {
                // Fetch feedback only for the specific client
                endpoint += `?clientId=${clientId}`;
            }
            try {
                const response = await fetch(endpoint);
                const data = await response.json();
                if (response.ok) {
                    setFeedbacks(data.records || []);
                } else {
                    setError("Failed to load feedback.");
                }
            } catch (error) {
                setError("An error occurred while fetching feedback.");
                console.error("Error fetching feedback:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedbacks();
    }, [clientId, role]);

    if (loading) {
        return <p>Loading feedback...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="dashboard-container">
            <h2>{role === 'client' ? 'Your Feedback' : 'All Client Feedback'}</h2>
            <FeedbackList feedbacks={feedbacks} />
        </div>
    );
}

export default Dashboard;
